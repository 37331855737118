<template>
  <table-view
    ref="tableView"
    url="/car/tests/listForPage"
    delete-url="/car/tests/deleteById"
    :filterable="false"
    :filter-form="dataForm"
    :batch-removable="isAuthed('car_check_delete')"
    :addable="isAuthed('car_check_add')"
    :editable="isAuthed('car_check_update')"
    :viewable="true"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input clearable v-model="dataForm.number" placeholder="自编号"></el-input>
      <el-input clearable v-model="dataForm.licensePlate" placeholder="车牌号码"></el-input>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'car-detection',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'number', label: '自编号', width: 100 },
        { id: 2, prop: 'licensePlate', label: '车牌号码', width: 120 },
        { id: 3, prop: 'carSize', label: '车辆型号', width: 120 },
        { id: 4, prop: 'chassis', label: '底盘', width: 100 },
        { id: 5, prop: 'companyName', label: '所属公司', width: 120 },
        {
          id: 6,
          prop: 'testsType',
          label: '检测类型',
          width: 100,
          formatter: (row, column, cellValue) => {
            const type = this.testTypes.find(t => t.dictId === parseInt(cellValue))
            return type ? type.dictName : ''
          }
        },
        { id: 7, prop: 'testsDate', label: '检测日期', width: 100 },
        { id: 8, prop: 'testsExpireDate', label: '下次检测日期', width: 120 },
        { id: 9, prop: 'testsCompany', label: '检测单位', minWidth: 100 }
      ],
      dataForm: {
        number: '',
        licensePlate: ''
      },
      testTypes: []
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  },

  async created () {
    const data = await this.$http({
      url: this.$http.adornUrl('/common/carTestsType'),
      method: 'post'
    })
    this.testTypes = data.datas
  }
}
</script>
